import * as React from "react"


const InnerScrollMenu = ({ children, title, subtitle }) => {

    const childrenListed = children.map((a, i) => {
        return (
            <li key={i}>{a}</li>
        )
    });

    return (
      <div className='inner-scroll-menu'>
          <h2>{ title }</h2>
          <p className='subtitle'>{ subtitle }</p>          
          <ul className='links'>
            { childrenListed }
          </ul>
      </div>   
    )
}
  
export default InnerScrollMenu