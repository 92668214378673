import * as React from "react"
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import SideBySide from "../components/SideBySide";
import MainArea from  '../components/MainArea';
import InnerScrollMenu from '../components/InnerScrollMenu';
import NextBanner from "../components/NextBanner";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import {Helmet} from 'react-helmet';
import { StaticImage } from "gatsby-plugin-image";


// markup
const OurSchemePage = () => {
    return (
        <Layout>
            <Helmet>
                <title>Our scheme | Bluetree Group</title>
                <meta name="description" content="More information about the Bluetree Graduate Management Programme" />
            </Helmet> 
            <Banner title="Fast track to leadership">
                <StaticImage src={"../assets/banners/ourscheme-banner.jpg"} alt="Employee inspecting printing" />                                
            </Banner>   
            <MainArea>

                <SideBySide>
                        <div>
                            <h2>An overview</h2>
                            <p>If you’ve got the right attitude and values and are willing to work hard then the Bluetree Group Graduate Management Programme is for you. We’ll provide all of the training, support, and opportunities you will need to fulfil your potential.</p>
                            <p>Working with leadership experts, we’ve created a bespoke management training programme that will build your skills in change management, communication, team-building and more, as well as ensuring you have the tools and techniques to manage your own wellbeing, resilience, and mental health.</p>
                            <p>Alongside these personal development sessions, you’ll be supported by our board members as you tackle business-specific opportunities and challenges. You’ll lead a number of projects, which could be as diverse as embedding a new CRM platform, or developing a new clinical testing process. </p>
                            <p>In addition, you’ll have the opportunity to explore the wealth of ‘front end’ roles available whilst rotating through different departments in our two divisions, helping you discover where your future talents may lie.   </p>
                            <p>And once the year is over, we’ll discuss together how we can best match your ambitions with the opportunities and roles available in our team.</p>
                        </div>
                    <StaticImage width="800" height="800" src={"../assets/content/facility.jpg"} />                    
                </SideBySide>
                <InnerScrollMenu title="Indicative graduate management programme" subtitle="Click to go straight to a section">
                    <a href="#induction">Induction</a>
                    <a href="#firstsecondment">First secondment</a>
                    <a href="#shortlearns">Short learns</a>
                    <a href="#secondsecondment">Second secondment</a>
                    <a href="#quarterlydevelopmentsessions">Quarterly development sessions</a>
                    <a href="#thirdsecondment">Third secondment</a>
                    <a href="#finaldevelopmentsession">Final development session</a>
                </InnerScrollMenu>
                <div className='consecutive-panes'>
                    <SideBySide>                        
                        <StaticImage width="600" src={"../assets/content/stage-1.jpg"} alt="" className="has-next-arrow" />
                        <div className='inset-text'>
                            <a name="induction"></a>
                            <h2>Induction</h2>
                            <p>You will be welcomed to Bluetree Group by our co-founders James and Adam who will outline their vision and values and introduce you to the business. </p>
                        </div>
                    </SideBySide>
                    <SideBySide>
                        <div className='inset-text'>
                            <a name="firstsecondment"></a>
                            <h2>First secondment</h2>
                            <p>An initial secondment could see you working in the UK’s largest online printing business, instantprint.</p>
                            <p>Founded by Adam and James in 2009 when they realised that creating print should be easier for small businesses, instantprint now manages over 700,000 clients and has a turnover of over £25,000,000 per year.</p>
                            <p>You’ll learn the processes from order placement through to fulfilment, getting familiarised with our cutting-edge technology and responsive customer service. </p>
                            <p>You could be tasked with a project exploring how the business can achieve net zero carbon status by 2030, which you’d then present back to the board.</p>
                        </div>
                        <StaticImage width="600"  src={"../assets/content/777A8013.JPG"} alt="" className="has-next-arrow" />                    
                    </SideBySide>
                    <SideBySide>
                        <StaticImage width="600"  src={"../assets/content/stage-3.jpg"} alt="" className="has-next-arrow" />
                        <div className='inset-text'>
                            <a name="shortlearns"></a>
                            <h2>Short learns</h2>
                            <p>Throughout the programme you’ll benefit from a series of ‘short learn’ training sessions. These range from one- to three- hour long sessions delivered by internal and external specialists.</p>
                            <p>They’ll cover practicalities such as: commerciality; product journey; getting you home safe (H&S); and hiring the best people; as well as more holistic topics such as: wellbeing and resilience; and supporting mental health in the workplace.</p>                            
                        </div>
                    </SideBySide>
                    <SideBySide>
                        <div className='inset-text'>
                        <a name="secondsecondment"></a>
                            <h2>Second secondment</h2>
                            <p>Your second secondment could see you working in our newest division, Bluetree Medical. Established in 2019 as a direct response to the COVID-19 pandemic, Bluetree Medical is a business of firsts.</p>
                            <p>We were the first UK manufacturer to deliver Type IIR surgical masks to the NHS during the pandemic; as well as the first UK business to develop a transparent facemask of respirator standard, in partnership with AlderHey Hospital Innovation Hub.</p>
                            <p>In less than two years, we’ve rapidly built a portfolio of medical consumables, having invested nearly £20m in an onsite laboratory, and in-house meltblown production. The result being we’ve secured tenders supplying everyone from local dentists and vets; the NHS and local NHS Trusts; through to the UEFA Euro 2020 Football Championships. You’ll learn about production, testing, and compliance.</p>
                            <p>Specific projects could range from developing testing protocols, to identifying the needs of healthcare professionals and shaping future product launches.</p>
                        </div>
                        <StaticImage width="600"  src={"../assets/content/777A3830.JPG"} alt="" className="has-next-arrow" />                    
                    </SideBySide>
                    <SideBySide>
                        <StaticImage width="600"  src={"../assets/content/stage-5.jpg"} alt="" className="has-next-arrow" />                    
                        <div className='inset-text'>
                        <a name="quarterlydevelopmentsessions"></a>
                            <h2>Quarterly development sessions</h2>
                            <p>After three, six and nine months on the programme, you’ll participate in our three-day leadership development away-days. These three-day off-site sessions will provide you with the opportunity to feedback on your experiences and how you’ve applied your skills training. </p>
                            <p>You’ll have more in-depth management training covering topics such as: change management; problem solving; continuous improvement; communicating effectively; motivating others; building trust; conflict resolution; and celebrating success.</p>
                            <p>After presenting back your individual projects, there will also be the opportunity to strengthen relationships with the board over dinner.</p>
                        </div>

                    </SideBySide>
                    <SideBySide>

                        <div className='inset-text'>
                        <a name="thirdsecondment"></a>
                            <h2>Third secondment</h2>
                            <p>Your final secondment could see you back in the print side of the business, this time delivering added value services to knowledgeable print buyers such as graphic designers, sign makers and marketing agencies.</p>                                   
                            <p>You’ll make outsourcing print a smooth process, leveraging our suite of reseller tools. Developing customer service skills to attract and retain these major clients will be core to this role.</p>  
                            <p>Projects could include developing white label services for clients, or incorporating new CRM platforms within the division.</p>                          
                        </div>
                        <StaticImage width="600"  src={"../assets/content/stage-6.jpg"} alt="" className="has-next-arrow" />                    
                    </SideBySide>
                    <SideBySide>
                        <StaticImage width="600"  src={"../assets/content/777A8067.JPG"} alt="" className="has-next-arrow" />                    
                        <div className='inset-text'>
                        <a name="finaldevelopmentsession"></a>
                            <h2>Final development session</h2>
                            <p>This final development awayday will provide an opportunity to share and receive feedback on learnings, obstacles, and challenges throughout the year. </p>
                            <p>You’ll have 1-2-1 meetings to discuss your future role within Bluetree Group and identify the best opportunities available which are appropriate to the skills you’ve developed and are keen to explore further. </p>
                            <p>Following completion of the graduate management programme, participants can apply to join the next cohort for Advanced Leadership, to ensure continuous professional development. </p>                       
                        </div>

                    </SideBySide>
                </div>
                <NextBanner destination="/benefits">
                    <strong>Next:</strong> Accelerate your career
                </NextBanner>
            </MainArea>
            


        </Layout>     
    )
  }
   
  export default OurSchemePage
  